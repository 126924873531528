<template>
  <div class="about">
    <div class="yqm">
      <img class="logo" src="@/assets/logo.png" alt="">

      <div class="flex-2">
        <div class="panel">
          <h4>上海嘤其鸣网络科技有限公司</h4>
          <h6>SHANGHAI YINGQIMING NET TECHNOLOGY CO.,LTD</h6>
          <p class="con-p">从事网络科技、计算机信息科技、智能科技领域内的技术开发、技术咨询、技术服务、技术转让，计算机软件开发，设计、制作各类广告，动漫设计，电子商务（不得从事金融业务），会务服务，展览展示服务，企业管理咨询，商务信息咨询，市场营销策划，电子产品、数码产品、计算机、软件及辅助设备的批发、零售。【依法须经批准的项目，经相关部门批准后方可开展经营活动。</p>
 
        </div>
      </div>
      <div class="panel">
        <div class="contact-info">
          <h4>联系方式：</h4>
          <p class="con-p"> 电话/phone：19121454659 <br> 邮箱/email：343410096@qq.com <br> 地址/address: 上海市奉贤区岚丰路1150号1幢4614室 </p>
        </div>
      </div>
      <div class="footer flex-1">
        <div class="copyright">&copy;2021</div>
        <div class="company">上海嘤其鸣网络科技有限公司</div>
        <div class="icp">&nbsp;沪ICP备 2021034677号-1</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.about {
  height: 100%;
  background-color: #e1e0c7;
}
.logo {
  height: 100px;
  width: auto;
  padding-top: 20px;
}
.yqm {
  width: 70%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "SimSun";
  .panel {
    margin-top: -10%;
    width: 100%;
  }
  .panel h4 {
    margin: 0;
    font-size: 2.4rem;
  }
  .panel p {
    font-size: 2rem;
  }
  .flex-1,
  .flex-2 {
    display: flex;
    align-items: center;
  }
  .flex-1 {
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .flex-2 {
    flex: 2;
    justify-content: center;
  }
  .footer {
    padding: 1.5rem 0;
    color: #999;
    font-size: 1.2rem;
  }
  .copyright {
    font-size: 1.4rem;
  }
  .con-p {
    padding-top: 20px;
  }
  .contact-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .verticle-mode {
    writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;      
    writing-mode: vertical-rl;
    p {
      line-height: 1.5;
    }
  }
}
</style>